import React from "react";
import classes from "./office365.module.scss";
import valueImg1 from "../../../../assets/images/icon_kidsvalue1.png";
//../../assets/images/icon_kidsvalue1.png
export default function Office365() {
  //  <img src="https://github.com/GrimStarr/EImages/blob/main/images/Microsoft%20logo.png?raw=true" />
  return (
    <div className={classes.Container}>
      <div className={classes.Texter}>
        <div className={classes.Text1}>
          <h2>MICROSOFT OFFICE ӨДӨР ТУТМЫН ХЭРЭГЛЭЭНИЙ ПРОГРАМ</h2>
          <p>
            Office 365 нь зардлаа бууруулахад олон талаар нөлөөлдөг. Юуны
            түрүүнд та байгууллагын дотоод сүлжээг (интранет) хариуцуулахаар хүн
            хөлслөх хэрэггүй болно. Учир нь Office 365-д үүнийг орлох апп бий.
            Бас та хамгаалалтын систем худалдаж авах шаардлагагүй. Яагаад гэвэл
            таны файлууд Microsoft-ийн хамгаалалт дор үүлэн технологиор
            хадгалагдаж байх юм. Мэдээж Office 365 дээр байнга шинэчлэл,
            өөрчлөлтүүд хийгдэж байх учир та оффисын програмуудаа шинэчлэх,
            дахин дараагийн хувилбарыг худалдан авах шаардлагагүй болно. Office
            365-д уламжлалт оффисын програмууд төдийгүй таны ажлын хөнгөвчлөх
            олон өөр апп байгааг санаарай.
          </p>
        </div>
        <div className={classes.Text2}>
          <img src="https://github.com/GrimStarr/EImages/blob/main/images/MCO%20640.png?raw=true" />
        </div>
      </div>
      <div className={classes.Items}>
        <div className={classes.item}>
          <img src={valueImg1} />
          <h2>БАЙГУУЛЛАГЫН ДОТООД ХАРИЛЦААГ САЙЖРУУЛАХ</h2>
          <p>
            Insert your content hereБайгууллагын ажилтнууд баг, хэлтэс, өрөө,
            оффис гээд олон зүйлээр тусгаарлагддаг. Үүнээс болж тогтмол бие
            биетэйгээ харилцаад байдаггүй. Харин энэ асуудлыг онлайнаар
            шийдвэрлэх боломжтой. Office 365-д байгууллагын дотоод харилцааг
            дэмждэг олон аппууд бий. Үүнд: Exchange, SharePoint, Teams,
            OneDrive, Planner, Power Bi зэрэг аппууд орно.
          </p>
        </div>
        <div className={classes.item}>
          <img src={valueImg1} />
          <h2>АЖЛАА ХУРДТАЙ ЯВУУЛАХ</h2>
          <p>
            Insert your content hereАжиллах хүчин тархан байрлах болсон өнөө үед
            Office 365 таныг ажлаа саадгүй хурдан явуулахад туслах болно. Office
            365 үүлэн технологид суурилдаг учир хэрэглэгчид хаанаас ч, ямар ч
            төхөөрөмжөөр хандах боломжтой. Өөр байршлаас ажилласан ч, өөр улсын
            иргэдийг ажилд авсан ч ажил саадгүй үргэлжлэх боломжтой.
          </p>
        </div>
        <div className={classes.item}>
          <img src={valueImg1} />
          <h2>АЖИЛТНУУДЫН БҮТЭЭМЖИЙГ ДЭЭШЛҮҮЛЭХ</h2>
          <p>
            Хэрэглэгчид кофе ууж суунгаа өөрийн гар утсаар ажлын төлөвлөгөө,
            гүйцэтгэлээ хянах, оффис дээр өрнөж буй хурлын үйл явц, илтгэлүүдийг
            харах нөхцөлийг Office 365 олгодог. Planner апп-ийн тусламжтайгаар
            төлөвлөгөө гарган хугацаа товлож, гүйцэтгэгчийг зааж, гүйцэтгэлийн
            хялбарчилсан тайлан авдаг учир ажилтан тус бүр өөрийн бүтээмжийг
            хянах боломжтой.
          </p>
        </div>
        <div className={classes.item}>
          <img src={valueImg1} />
          <h2>БАЙГУУЛЛАГЫН IT ҮЙЛЧИЛГЭЭГ САЙЖРУУЛАХ</h2>
          <p>
            Байгууллага бүр өөрсдийн дотоод сүлжээ, хамгаалалтын системтэй байх
            албагүй. Ялангуяа мэдээлэл технологийн компани биш бол энэ тал дээр
            хүн хөлсөлж, нэмэлт хөрөнгө зарах шаардлагагүй байдаг. Өөрсдийн IT
            үйлчилгээг илүү мэргэжлийн найдвартай газарт нь даатгаж болно шүү
            дээ. Энгийн хамгаалалтын систем, дотоод сервер орчноос
            Microsoft-ийнх илүү найдвартай бөгөөд хямдхан. Иймд Office 365
            ашиглан байгууллагын файлаа дотооддоо аюулгүй хуваалцах боломжтой
            юм.
          </p>
        </div>
      </div>
    </div>
  );
}
