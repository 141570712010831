import React from 'react';
import Header from "../../components/Header/Header";

const Privacy = () => {
  return (
    <div>
      <Header isHome={false} />
      <h1>Privacy Policy</h1>

      <p>This Privacy Policy governs the manner in which the Digital ID Power App ("the App") collects, uses, maintains, and discloses information collected from users ("Users"). This Privacy Policy applies to the App and all products and services offered by Empasoft LLC.</p>

      <h2>1. Personal Identification Information:</h2>
      <p>We may collect personal identification information from Users in various ways, including but not limited to when Users download the App, register on the App, fill out a form, and in connection with other activities, services, features, or resources we make available on the App. Users may be asked for, as appropriate, name, email address, phone number. Users may, however, visit the App anonymously. We will collect personal identification information from Users only if they voluntarily submit such information to us. Users can always refuse to supply personally identification information, except that it may prevent them from engaging in certain App-related activities.</p>

      <h2>2. Non-personal Identification Information:</h2>
      <p>We may collect non-personal identification information about Users whenever they interact with the App. Non-personal identification information may include the browser name, the type of computer, and technical information about Users means of connection to our App, such as the operating system and the Internet service providers utilized and other similar information.</p>

      <h2>3. How We Use Collected Information:</h2>
      <ul>
        <li>To personalize user experience: We may use information in the aggregate to understand how our Users as a group use the services and resources provided on the App.</li>
        <li>To improve our App: We continually strive to improve our offerings based on the information and feedback we receive from you.</li>
        <li>To send periodic emails: We may use the email address to send User information and updates pertaining to their order. It may also be used to respond to their inquiries, questions, and/or other requests.</li>
      </ul>

      <h2>4. How We Protect Your Information:</h2>
      <p>We adopt appropriate data collection, storage, and processing practices and security measures to protect against unauthorized access, alteration, disclosure, or destruction of your personal information, username, password, transaction information, and data stored on our App.</p>

      <h2>5. Sharing Your Personal Information:</h2>
      <p>We do not sell, trade, or rent Users personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates, and advertisers for the purposes outlined above.</p>

      <h2>6. Changes to This Privacy Policy:</h2>
      <p>We have the discretion to update this privacy policy at any time. When we do, we will revise the updated date at the bottom of this page. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.</p>

      <h2>7. Your Acceptance of These Terms:</h2>
      <p>By using this App, you signify your acceptance of this policy. If you do not agree to this policy, please do not use our App. Your continued use of the App following the posting of changes to this policy will be deemed your acceptance of those changes.</p>

      <h2>8. Contacting Us:</h2>
      <p>If you have any questions about this Privacy Policy, the practices of this App, or your dealings with this App, please contact us at support@empasoft.mn.</p>

      <p>This document was last updated on 2024.</p>
    </div>
  );
};

export default Privacy;
